<template>
    <div>
        <ul class="handle_list">
            <li
                @click="toLink(item.routeName, item.options)"
                v-for="(item, index) in menuList"
                :key="index"
            >
                <img :src="item.icon" alt="" @click="Artificial(index)" />
                <p>{{ item.menuName }}</p>
            </li>
        </ul>
    </div>
</template>

<script>
import API from "../../common/api/api"
import { toLink } from "../../common/utils/router"
export default {
    name: "PersonalInformation",
    components: {},
    data() {
        return {
            menuList: [
                {
                    menuName: "账号绑定",
                    icon: require("../../assets/images/zh.png"),
                    routeName: "personal",
                    options: {},
                },
                {
                    menuName: "人工客服",
                    icon: require("../../assets/images/kf.png"),
                    routeName: "",
                    options: {},
                },
            ],
        }
    },

    // 组件方法
    methods: {
        toLink,
        Artificial(index) {
            if (index == 1) {
                var token = localStorage.getItem("token")
                API.getJson("wx_url", {
                    token: token,
                }).then(res => {
                    this.nickType = res.list[0].nickType
                    this.clientId = res.list[0].nickId
                    this.otherParams = `{"nickName":"${res.list[0].nickName}"}`
                    let sit = `https://webchat.7moor.com/wapchat.html?accessId=b8e25cb0-5ca3-11ec-99c8-3739e01ad41c&fromUrl=&urlTitle="${this.nickType}"&language=ZHCN&clientId=${this.clientId}&otherParams=${this.otherParams}`
                    window.location.href = sit
                })
            }
        },
    },

    computed: {},

    mounted() {},
}
</script> 

<style scoped lang='scss' >
.handle_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: px2rem(40);
}

.handle_list li {
    flex: 0 0 50%;
    margin-bottom: px2rem(60);
    text-align: center;
}

.handle_list li img {
    width: px2rem(48);
    height: px2rem(48);
}

.handle_list li p {
    font-size: px2rem(26);
    color: #333;
}
</style>